/*
 * Scale the element and make it jiggle. Useful to
 * draw the user attention on the element. Use sparingly.
 */
@keyframes scale-and-jiggle {
  0% {
    transform: rotate(0deg) scale(1);
  }
  30% {
    transform: rotate(0deg) scale(1.75);
  }
  40% {
    transform: rotate(4deg) scale(1.75);
  }
  50% {
    transform: rotate(-4deg) scale(1.75);
  }
  60% {
    transform: rotate(4deg) scale(1.75);
  }
  70% {
    transform: rotate(-4deg) scale(1.75);
  }
  80% {
    transform: rotate(4deg) scale(1.75);
  }
  90% {
    transform: rotate(-4deg) scale(1.75);
  }
  100% {
    transform: rotate(0deg) scale(1.75);
  }
}

/*
 * Fade in the element. Used for the MainFrame at startup, and
 * can be used to smoothly show other elements after a loading.
 */
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Animate the element like if it was just finished being swiped up. */
@keyframes swipe-up-ending {
  from {
    transform: translateY(15px);
  }
  to {
    transform: translateY(0px);
  }
}

/* Useful to hint the user that a vertical scroll is needed, in in-app tutorials. */
@keyframes vertical-translate {
  from {
    transform: translateY(-10px);
  }
  to {
    transform: translateY(10px);
  }
}

/* Useful to hint the user that a horizontal scroll is needed, in in-app tutorials. */
@keyframes horizontal-translate {
  from {
    transform: translateX(-10px);
  }
  to {
    transform: translateX(10px);
  }
}

/* Useful for loaders */
@keyframes spin-background {
  from {
    transform: translate(-50%, -50%) rotate(0);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
