@font-face {
  font-family: 'Fira Sans';
  src: url('../../fonts/FiraSans-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Fira Sans';
  src: url('../../fonts/FiraSans-Bold.ttf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Fira Sans';
  src: url('../../fonts/FiraSans-SemiBold.ttf');
  font-weight: 600;
  font-style: normal;
}
