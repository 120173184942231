/* This is the styling applied to markdown (like the one used for Changelog). */

.gd-markdown {
  font-family: var(--gdevelop-modern-font-family);
}

.gd-markdown.standalone-text-container {
  font-size: 16px;
  line-height: 1.3;
}

.gd-markdown h1 {
  margin-bottom: 10px;
}

.gd-markdown h2 {
  margin-bottom: 10px;
}

.gd-markdown ul {
  word-break: break-word;
}

.gd-markdown blockquote {
  border-left: 5px solid var(--markdown-blockquote-border-left-color);
  padding-left: 15px;
  margin-left: 20px;
}

.gd-markdown a {
  color: var(--markdown-link-color);
  cursor: pointer;
}

.gd-markdown code {
  font-family: "Lucida Console", Monaco, monospace;
  -webkit-user-select: text;
  user-select: text;
  cursor: text;
}

.gd-markdown img {
  max-width: 100%;
}

.gd-markdown p {
  margin-block-start: 10px;
  margin-block-end: 10px;
}

.gd-markdown table {
  border-collapse: collapse;
}
.gd-markdown th,
.gd-markdown td {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid currentColor;
}